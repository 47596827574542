<template>
  <div class="licenceApply">
    <div class="submitForm">
      <el-form ref="form" disabled :model="submitForm" label-position="top" label-width="140px">
        <div class="title">机构信息</div>
        <el-row style="margin-top: 20px">
          <el-col :span="8">
            <div>
              <span>姓名： </span>
              <span>{{ submitForm.userName || '--' }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span>联系电话： </span>
              <span>{{ submitForm.userPhone || '--'  }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="8">
            <div>
              <span>身份证号： </span>
              <span>{{ submitForm.cardId || '--'  }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span>出生日期： </span>
              <span>{{ submitForm.birthday || '--'  }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="8">
            <div>
              <span>年龄： </span>
              <span>{{ submitForm.old || '--'  }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span>性别： </span>
              <span>{{ submitForm.sexText || '--'  }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="8">
            <div>
              <span>所属区划： </span>
              <span>{{ submitForm.compartment || '--'  }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span>户籍地址： </span>
              <span>{{ submitForm.residenceAddress || '--'  }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="8">
            <div>
              <span>现居地址： </span>
              <span>{{ submitForm.homeAddress || '--'  }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              <span>身份类别： </span>
              <span>{{ submitForm.subsidyTypeText || '--'  }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="8">
            <div>
              <span>月标准： </span>
              <span>{{ submitForm.monthlyStandard || '--'  }} 元</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="uploadLine">
          <el-col :span="4" v-if="submitForm.cardFrontImage" style="text-align: center; margin-top: 20px">
            <el-form-item prop="image">
              <img
                v-if="submitForm.cardFrontImage"
                :src="submitForm.cardFrontImage"
                @click="$zoomIn([...submitForm.cardFrontImage])"
                width="200"
                height="200"
                alt=""
              />
              <div v-else class="imgEmpty">
                <i class="el-icon-plus"></i>
              </div>
            </el-form-item>
            <div class="flex-column tip_bottom">
              <span class="imp">身份证（人像面） </span>
            </div>
          </el-col>
          <el-col
            v-if="submitForm.cardBackImage"
            :span="4"
            style="text-align: center; margin-top: 20px"
          >
            <el-form-item prop="image">
              <img
                v-if="submitForm.cardBackImage"
                :src="submitForm.cardBackImage"
                @click="$zoomIn([...submitForm.cardBackImage])"
                width="200"
                height="200"
                alt=""
              />
              <div v-else class="imgEmpty">
                <i class="el-icon-plus"></i>
              </div>
            </el-form-item>
            <div class="flex-column tip_bottom">
              <span class="imp">身份证(国徽面) </span>
            </div>
          </el-col>
          <el-col
            v-if="submitForm.residenceOneselfImage"
            :span="4"
            style="text-align: center; margin-top: 20px"
          >
            <el-form-item prop="image">
              <img
                v-if="submitForm.residenceOneselfImage"
                :src="submitForm.residenceOneselfImage"
                @click="$zoomIn([...submitForm.residenceOneselfImage])"
                width="200"
                height="200"
                alt=""
              />
              <div v-else class="imgEmpty">
                <i class="el-icon-plus"></i>
              </div>
            </el-form-item>
            <div class="flex-column tip_bottom">
              <span class="imp">居民户口簿（本人页）</span>
            </div>
          </el-col>
          <el-col
            v-if="submitForm.residenceFirstImage"
            :span="4"
            style="text-align: center; margin-top: 20px"
          >
            <el-form-item prop="image">
              <img
                v-if="submitForm.residenceFirstImage"
                :src="submitForm.residenceFirstImage"
                @click="$zoomIn([...submitForm.residenceFirstImage])"
                width="200"
                height="200"
                alt=""
              />
              <div v-else class="imgEmpty">
                <i class="el-icon-plus"></i>
              </div>
            </el-form-item>
            <div class="flex-column tip_bottom">
              <span class="imp">居民户口簿（户主页）</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="uploadLine" v-if="submitForm.subsidyType === 2 || submitForm.subsidyType === 3">
          <div class="title">失能老年人信息</div>
          <el-row style="margin: 20px 0">
            <el-col :span="8">
              <div>
                <span>失能类别： </span>
                <span>{{ submitForm.disabilityCategoryText || '--'  }}</span>
              </div>
            </el-col>
          </el-row>
          <div v-if="submitForm.disabilityCategory === 1">
            <el-row style="margin: 20px 0">
              <el-col :span="8">
                <div>
                  <span>残疾类别： </span>
                  <span>{{ submitForm.handicapTypeText || '--'  }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <span>残疾等级： </span>
                  <span>{{ submitForm.handicapGradeText || '--'  }}</span>
                </div>
              </el-col>
            </el-row>
            <el-row style="margin: 20px 0">
              <el-col :span="8">
                <div>
                  <span>残疾人证号码： </span>
                  <span>{{ submitForm.handicapCard || '--'  }}</span>
                </div>
              </el-col>
            </el-row>
            <el-col :span="4" v-if="submitForm.handicapImage" style="text-align: center; margin-top: 20px">
              <el-form-item prop="image">
                <img
                  v-if="submitForm.handicapImage"
                  :src="submitForm.handicapImage"
                  @click="$zoomIn([...submitForm.handicapImage])"
                  width="200"
                  height="200"
                  alt=""
                />
                <div v-else class="imgEmpty">
                  <i class="el-icon-plus"></i>
                </div>
              </el-form-item>
              <div class="flex-column tip_bottom">
                <span class="imp">第二代残疾人证 </span>
              </div>
            </el-col>
          </div>
          <div v-if="submitForm.disabilityCategory === 2">
            <el-row style="margin: 20px 0">
              <el-col :span="8">
                <div>
                  <span>瘫痪卧床原因： </span>
                  <span>{{ submitForm.palsyReason || '--'  }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div>
                  <span>瘫痪卧床时间： </span>
                  <span>{{ submitForm.palsyTime || '--'  }}</span>
                </div>
              </el-col>
            </el-row>
            <el-col :span="4" v-if="submitForm.palsyImage" style="text-align: center; margin-top: 20px">
              <el-form-item prop="image">
                <img
                  v-if="submitForm.palsyImage"
                  :src="submitForm.palsyImage"
                  @click="$zoomIn([...submitForm.palsyImage])"
                  width="200"
                  height="200"
                  alt=""
                />
                <div v-else class="imgEmpty">
                  <i class="el-icon-plus"></i>
                </div>
              </el-form-item>
              <div class="flex-column tip_bottom">
                <span class="imp">医院开具的诊断结果 </span>
              </div>
            </el-col>
          </div>

        </el-row>
        <el-row class="uploadLine" v-if="submitForm.subsidyType === 1 || submitForm.subsidyType === 3">
          <div class="title">低保老年人信息</div>
          <el-row style="margin: 20px 0">
            <el-col :span="8">
              <div>
                <span>低保类型： </span>
                <span>{{ submitForm.allowanceTypeText || '--'  }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4" v-if="submitForm.allowanceImage" style="text-align: center; margin-top: 20px">
              <el-form-item prop="image">
                <img
                  v-if="submitForm.allowanceImage"
                  :src="submitForm.allowanceImage"
                  @click="$zoomIn([...submitForm.allowanceImage])"
                  width="200"
                  height="200"
                  alt=""
                />
                <div v-else class="imgEmpty">
                  <i class="el-icon-plus"></i>
                </div>
              </el-form-item>
              <div class="flex-column tip_bottom">
                <span class="imp">低保证（城市特困证） </span>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin: 20px 0">
            <div style="margin-bottom: 20px">银行卡信息</div>
            <el-row style="margin: 20px 0">
              <el-col :span="8">
                <div>
                  <span>银行卡号： </span>
                  <span>{{ submitForm.bankCard || '--'  }}</span>
                </div>
                <div style="margin-top: 20px">
                  <span>持卡人姓名： </span>
                  <span>{{ submitForm.bankUserName || '--'  }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div style="margin-top: 20px">
                  <span>开户行： </span>
                  <span>{{ submitForm.bankDeposit || '--'  }}</span>
                </div>
                <div style="margin-top: 20px">
                  <span>与申请人关系： </span>
                  <span>{{ submitForm.bankRelationship || '--'  }}</span>
                </div>
              </el-col>
            </el-row>
          </el-row>
          <el-row>
            <el-col :span="4" v-if="submitForm.bankImage" style="text-align: center; margin-top: 20px">
              <el-form-item prop="image">
                <img
                  v-if="submitForm.bankImage"
                  :src="submitForm.bankImage"
                  @click="$zoomIn([...submitForm.bankImage])"
                  width="200"
                  height="200"
                  alt=""
                />
                <div v-else class="imgEmpty">
                  <i class="el-icon-plus"></i>
                </div>
              </el-form-item>
              <div class="flex-column tip_bottom">
                <span class="imp">银行卡-正面 </span>
              </div>
            </el-col>
            <el-col
              v-if="submitForm.bankBackImage"
              :span="4"
              style="text-align: center; margin-top: 20px"
            >
              <el-form-item prop="image">
                <img
                  v-if="submitForm.bankBackImage"
                  :src="submitForm.bankBackImage"
                  @click="$zoomIn([...submitForm.bankBackImage])"
                  width="200"
                  height="200"
                  alt=""
                />
                <div v-else class="imgEmpty">
                  <i class="el-icon-plus"></i>
                </div>
              </el-form-item>
              <div class="flex-column tip_bottom">
                <span class="imp">银行卡-反面 </span>
              </div>
            </el-col>
          </el-row>
        </el-row>
        <div v-if="submitForm.ifAgent !== 1">
          <div class="title">委托代理人信息</div>
          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <div>
                <span>姓名： </span>
                <span>{{ submitForm.agentUserNam || '--'  }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span>身份证号： </span>
                <span>{{ submitForm.agentCardId || '--'  }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <div>
                <span>联系电话： </span>
                <span>{{ submitForm.agentPhone || '--'  }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span>性别： </span>
                <span>{{ submitForm.agentSexText || '--'  }}</span>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <div>
                <span>与申请人关系： </span>
                <span>{{ submitForm.agentRelationship || '--'  }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                <span>现居地址： </span>
                <span>{{ submitForm.agentAddress || '--'  }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="submitForm.cancelRecords.length">
          <div class="title">审核记录</div>
          <el-row style="margin-top: 10px">
            <el-col :span="12">
              <el-table :data="submitForm.cancelRecords" border style="width: 100%" :header-cell-style="headerCellStyle">
                <template slot="empty">
                  <IsEmpty />
                </template>
                <el-table-column type="index" label="序号" align="center" width="80" />
                <el-table-column prop="type" align="center" label="操作类型">
                  <template v-slot="scope">
                    <span>{{ ['提交', '审核'][scope.row.type] }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="state" align="center" label="审核状态">
                  <template v-slot="scope">
                    <span>{{ ['通过', '不通过'][scope.row.state] }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="createTime" align="center" label="操作时间" />
                <el-table-column prop="unitName" align="center" label="操作单位" />
                <el-table-column prop="examineAccount" align="center" label="操作人">
                  <template v-slot="scope">
                    <span>{{ scope.row.examineAccount || scope.row.unitName }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="refusalCause" align="center" label="不通过原因" />
              </el-table>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="btn">
        <el-button style="width: 120px; margin-right: 30px" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { canceldetail } from '@/api/highCollarSubsidy'
  export default {
    name: 'viewStopApply',
    data() {
      return {
        submitForm: {
          licenseFiling: '',
          orgDictName: '',
          serviceName: '',
          contactPerson: '',
          contactPhone: '',
          contactAddress: '',
          licenceNumber: '',
          businessLicenseNumber: '',
          serviceSubsidyApplication: '',
          unitRegistration: '',
          businessLicense: '',
          foodBusinessLicense: '',
          otherCertificates: [],
          collectionNumber: '',
          collectionAccount: '',
          bankOfDeposit: '',
          totalBeds: '',
          nursingBeds: '',
          auditRecordVoList: [],
          cancelRecords: []
        },
        rowId: ''
      }
    },
    beforeRouteEnter(to, from, next) {
      to.meta.title = '查看'
      next()
    },
    mounted() {
      this.rowId = this.$route.query.rowId
      this.detailsData()
    },
    methods: {
      async detailsData() {
        const res = await canceldetail({ id: this.rowId })
        this.submitForm = res.data
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  .btn {
    margin-top: 30px;
    text-align: center;
  }
  .licenceApply {
    width: 100%;
    padding: 15px 20px;
    background: #fff;
    .submitForm {
      margin-top: 10px;
      .title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
      }
      .tip {
        color: #7f7f7f;
        margin-bottom: 10px;
      }
      .tip_bottom {
        line-height: 20px;
      }
      .uploadLine {
        margin-top: 20px;
      }
      .imp::before {
        /* content: '*';
         color: #ff4949;
         margin-right: 4px;*/
      }
    }
    .wf300 {
      width: 300px;
    }
  }
  .imgEmpty {
    width: 200px;
    height: 200px;
    border: 1px dashed #c0c4cc;
    display: inline-block;
    text-align: center;
    background-color: #fbfdff;
    margin-top: 10px;
    i {
      font-size: 32px;
      color: #c0c4cc;
      text-align: center;
      line-height: 200px;
    }
  }
  img {
    cursor: pointer;
  }
  /*  .tip {
      position: relative;
    }
    .tip:after {
      content: '必须为收款账号对应的收款账户名称';
      position: absolute;
      right: -250px;
      top: 0px;
    }*/
  span {
    font-size: 14px !important;
  }
</style>
